<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header">
            <div slot="header">
              <i class="fa fa-th-large"></i><strong> Ventes</strong>
              <info-message>
                Nous présentons l'ensemble des produits qui sont destines à être vendus
              </info-message>
              <!--<div class="card-header-actions">
                <b-button variant="primary" :to="createHarvestRoute">
                  <i class="fa fa-plus"></i>
                  Créer une récolte
                </b-button>
              </div>-->
            </div>
            
            <div class="text-center" v-if="fetchingSaleList">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <b-tabs v-else-if="sales.length !=0" content-class="mt-3 border-0" v-model="saleTabOpen">
              <b-tab v-for="tab in tabs">
                <div slot="title" class="text-dark">
                  <i :class="tab.icon"></i> {{tab.title}} ({{tab.sales.length}})
                </div>
                <b-row class="exploitations">
                  <p class="text-center" v-if="tab.sales.length == 0">
                    Vous n'avez aucune vente {{tab.title}}.
                  </p>
                  <template v-else>
                    <b-col md="6" lg="4" xl="4" v-for="sale in tab.sales">
                      <b-card :img-src="asset(sale.picture)" no-body>
                        <div slot="header">
                          {{sale.name}}
                          <div class="card-header-actions">
                            <b-link href="#" class="card-header-action btn-setting">
                              <i class="icon-bell"></i>
                              <b-badge pill variant="danger">0</b-badge>
                              <b-badge pill variant="success">0</b-badge>
                            </b-link>
                          </div>
                        </div>
                        <b-list-group flush>
                          <b-list-group-item>
                            <i class="fa fa-clock-o mr-2 text-dark"></i>{{tab.timeTitle + ' ' + sale.currentDate}}
                          </b-list-group-item>
                          <b-list-group-item>
                            <i class="fa fa-leaf mr-2 text-success"></i>{{sale.culture.name}}
                          </b-list-group-item>
                          <b-list-group-item>
                            <i class="icon-layers mr-2 text-secondary"></i>
                            {{sale.quantity}} Kg
                          </b-list-group-item>
                          <b-list-group-item>
                            <i class="fa fa-money mr-2 text-success"></i>
                            {{sale.retailPrice}}
                            {{exploitationCurrency}} / Kg
                            (de detail)
                          </b-list-group-item>
                          <b-list-group-item>
                            <i class="fa fa-money mr-2 text-success"></i>
                            {{sale.wholesalePrice}}
                            {{exploitationCurrency}} / 100Kg
                            (de gros)
                          </b-list-group-item>
                        </b-list-group>
                        <div slot="footer" class="mt-2">
                          <b-button variant="success" class="mr-2" :to="sale.url" v-b-tooltip.hover
                                    title="Ouvrir la vente">
                            <i class="icon-size-fullscreen"></i>
                          </b-button>
                          <!--todo mettre le to pour la redirection-->
                          <b-button variant="secondary" class="mr-2"
                                    v-if="tab.type == 'planned' || tab.type == 'blocked'" v-b-tooltip
                                    :title="tab.type == 'blocked' ? 'Relancer la vente':'Lancer la vente'"
                                    @click="launchSale(sale,'save-start')">
                            <!--Lancer ou relancer une vente-->
                            <template v-if="sale.launching">
                              <b-spinner small class="mr-1"></b-spinner>
                              Lancement...
                            </template>
                            <i class="fa fa-play" v-else></i>
                          </b-button>
                          <b-button variant="danger" class="mr-2" v-if="tab.type == 'running'" v-b-tooltip.hover
                                    title="Clôturer la vente" @click="launchSale(sale,'close')">
                            <!--Cloturer une vente-->
                            <template v-if="sale.ending">
                              <b-spinner small class="mr-1"></b-spinner>
                              Clôture...
                            </template>
                            <i class="fa fa-ban" v-else></i>
                          </b-button>
                          <b-button variant="danger" class="mr-2" v-if="tab.type == 'abandoned'" v-b-tooltip.hover
                                    title="Supprimer la vente">
                            <!--Supprimer une vente-->
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>
                      </b-card>
                    </b-col>
                  </template>
                </b-row>
              </b-tab>
            </b-tabs>
            <p class="text-center" v-else>Vous n'avez crée aucune vente.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Toast, IO, asset, env, price} from "../../../helpers/index";
  
  export default {
    name: "ExploitationHarvests",
    title: "PIA - Ventes",
    data() {
      return {
        sales: [],
        error: null,
        fetchingSaleList: false,
        saleTabOpen: 0,
      }
    },
    created() {
      this.fetchingSaleList = true
      Api.get('/exploitation/product-sale/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            let isExistsSaleRunning = false
            this.sales = res.data.data.map(item => {
              let currentDate = ''
              switch (item.status.name) {
                case 'RUNNING':
                  isExistsSaleRunning = true
                  currentDate = item.startDate
                  break;
                case 'PLANNED':
                  currentDate = item.startDate
                  break;
                case 'TERMINATED':
                  currentDate = item.startDate
                  break;
                case 'BLOCKED':
                  currentDate = item.blockedDate
                  break;
                default:
                  currentDate = item.endDate
                  break;
              }
              
              
              return {
                ...item,
                url: '/exploitation/' + this.exploitationId + '/sales/' + item.id,
                currentDate: currentDate.split(' ').join(' à '),
                launching: false,
                ending: false
              }
            })
            // to open a tab that has at least one production
            if (!isExistsSaleRunning && this.sales.length != 0)
              switch (this.sales[0].status.name) {
                case 'TERMINATED' :
                  this.saleTabOpen = 1
                  break
                case 'PLANNED' :
                  this.saleTabOpen = 2
                  break
                case 'ABANDONED' :
                  this.saleTabOpen = 3
                  break
                default :
                  this.saleTabOpen = 4
              }
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingSaleList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      },
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      createHarvestRoute() {
        return {
          name: 'ExploitationCreateHarvest',
          params: {id: this.exploitationId}
        }
      },
      exploitationCurrency() {
        return price()
      },
      
      tabs() {
        return [
          {
            icon: 'fa fa-spinner',
            title: 'En cours',
            timeTitle: 'Débutée le',
            sales: this.sales.filter(item => item.status.name == 'RUNNING'),
            type: 'running'
          },
          {
            icon: 'fa fa-stop-circle',
            title: 'Terminées',
            timeTitle: 'Terminée le',
            sales: this.sales.filter(item => item.status.name == 'TERMINATED'),
            type: 'terminated'
          },
          {
            icon: 'fa fa-clock-o',
            title: 'Planifiées',
            timeTitle: 'Planifiée pour le',
            sales: this.sales.filter(item => item.status.name == 'PLANNED'),
            type: 'planned'
          },
          {
            icon: 'fa fa-th',
            title: 'Abandonnées',
            timeTitle: 'Abandonnée le',
            sales: this.sales.filter(item => item.status.name == 'ABANDONED'),
            type: 'abandoned'
          },
          {
            icon: 'fa fa-ban',
            title: 'Bloquées',
            timeTitle: 'Bloquée le',
            sales: this.sales.filter(item => item.status.name == 'BLOCKED'),
            type: 'blocked'
          },
        ]
      }
    },
    methods: {
      asset(path) {
        return asset(path, env('DEFAULT_PARCEL_PICTURE'))
      },
      onExport() {
        IO.exportDataTable(this.sales, 'sales', {
          title: 'Exploitation: ' + this.$store.getters.exploitationName,
          name: 'Liste des récoltes',
          headers: [
            {title: 'Aperçu', key: 'picture', width: 'auto', asset: this.asset},
            {title: 'Culture', key: 'culture.name', width: '*'},
            {title: 'Parcelle', key: 'parcel.name', width: '*'},
            {title: 'Quantité', key: 'qty', width: '*'},
            {title: 'Date', key: 'createdAt', width: 'auto'},
          ]
        })
      },
      async launchSale(sale, state) {
        this.sales = this.sales.map(saleItem => {
          if (saleItem.id == sale.id)
            return {
              ...saleItem,
              launching: state == 'save-start',
              ending: state == 'close'
            }
          else return saleItem
        })
        let currentPicture = 'nothing'
        if (sale.picture) {
          let intPicture = await fetch(asset(sale.picture)).then(r => r.blob())
          await Promise.all([new Promise(((resolve, reject) => {
            
            const reader = new FileReader();
            
            reader.onload = (e) => {
              resolve(e.target.result)
            };
            reader.readAsDataURL(intPicture);
          }))]).then(value => {
            currentPicture = value[0]
          })
        } else {
          currentPicture = sale.picture
        }
        Api.post(`/exploitation/product-sale/${state}`, {
          exploitationId: this.exploitationId,
          saleId: sale.id,
          picture: currentPicture,
          name: sale.name,
          staff: sale.staff,
          transportCost: sale.transportCost,
          transportModeId: sale.transportModeId,
          warehouses: sale.warehouses,
          retailPrice: sale.retailPrice,
          wholesalePrice: sale.wholesalePrice,
          saleOptionId: sale.saleOptionId,
          estimationSale: sale.estimationSale,
          startDate: sale.startDate,
          comment: sale.comment
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              const saleResult = res.data.data
              this.sales = this.sales.map(saleItem => {
                if (saleItem.id == sale.id)
                  return {
                    ...saleItem,
                    status: saleResult.status,
                    launching: false,
                    ending: false
                  }
                else return saleItem
              })
              if (state == 'save-start') {
                Toast.success('Vente démarrée avec succès !')
                if (saleResult.status.name == 'RUNNING')
                  this.saleTabOpen = 0
                else
                  this.saleTabOpen = 4
              }
              else {
                Toast.success('Vente clôturée avec succès !')
                this.saleTabOpen = 1
              }
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          
          })
      }
    }
  }
</script>

<style scoped>
  parcel-name{
    font-weight: bold;
    font-size: 16px;
    font-family: "Verdana Pro Cond Black";
  }
  
  parcel-name:hover{
    color: #07C;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .card-img{
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

</style>
